// Sizes
$opened_width: 220;
$closed_width: 70;
$closed_width_mobile: 60;
$top_height: 60;
$actions_bar_height: 60; // 46
$search_bar_height: 90;
$top_fixed_header_height: 120;
$tabs_height: 60;
$compras_row_height: 73;
$itens_column_width: 370;

// New Colors
$azulpadrao: #0076D6;
$azulborder: #0076D6;
$azulboxshadow: rgba(68, 142, 195, 1);
$azulradiodot: #0076D6;

// Colors
$grey_background: #f5f5f5; // f1f3f6 f5f6f9
$menu_background: #FFFFFF; // 364156 2f3d4f 2f3c4c 2b303b 062d47 2e3f57
$green: #00b963;
$yellow: #ffd25c;
// #1c3960   233e64   #243a58
$s_cinza: #dcdcdc;
$s_cinza_text: #bdbdbd;
$s_amarelo: #f7cf66;
$s_azul: #63a4d0;
$s_azul_escuro: #4c5d70;
$s_verde: #7dbc81;
$s_vermelho: #e26262;
$verde: #56A049;
$vermelho: #F11919;
$azul: #447AB2;

// Z-index's
$zindex_topbar: 1010 !default;
$zindex_leftbar: 1030 !default;

// QC Online variables
$fornecedores_row_height: 176;
$fornecedores_row_rc_height: 162;
$rc_right_margin: 7;
$rc_info_row_height: 100;
$valores_row_height: 42;
$itens_col_width: 250;
$itens_col_width_m: 56;
$itens_rcs_width: 286;

$qc_borders_color: #d6dbe6;
$labels_color: #262626;

// Compra review
$status_bar_height: 270;
$menu_bar_height: 52;

/* ================================================================== *\



    #Mixins



\* ================================================================== */
.modal-edicao-compra .ant-modal-footer {
	display: none;
	background-color: #FFFF;
	border-top: 0px;
	padding: 0;
}
.modal-edicao-compra .ant-modal-header {
	border-bottom: 0;
}

@mixin transition-one($transition1) {
	-webkit-transition: $transition1;
	-moz-transition: $transition1;
	-o-transition: $transition1;
	-ms-transition: $transition1;
	transition: $transition1;
}

@mixin transition-two($transition1, $transition2) {
	-webkit-transition: $transition1, $transition2;
	-moz-transition: $transition1, $transition2;
	-o-transition: $transition1, $transition2;
	-ms-transition: $transition1, $transition2;
	transition: $transition1, $transition2;
}

@mixin transition-three($transition1, $transition2, $transition3) {
	-webkit-transition: $transition1, $transition2, $transition3;
	-moz-transition: $transition1, $transition2, $transition3;
	-o-transition: $transition1, $transition2, $transition3;
	-ms-transition: $transition1, $transition2, $transition3;
	transition: $transition1, $transition2, $transition3;
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin box-shadow($shadows) {
	-webkit-box-shadow: $shadows;
	-moz-box-shadow: $shadows;
	box-shadow: $shadows;
}

@mixin user-select() {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: -moz-none;
	-ms-user-select: none;
	user-select: none;
}

@mixin background-size($sizes) {
	-webkit-background-size: $sizes;
	-moz-background-size: $sizes;
	-o-background-size: $sizes;
	background-size: $sizes;
}

@mixin media-size($size) {
	@if $size==768 {
		@media (min-width: 768px) {
			@content;
		}
	}

	@if $size==992 {
		@media (min-width: 992px) {
			@content;
		}
	}

	@if $size==1200 {
		@media (min-width: 1200px) {
			@content;
		}
	}
}
