@import "./global_variables.scss";

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 300 !important;
  // line-height: 1.1 !important;
}

.input-md {
  // height: 42px !important;
  // font-size: 16px !important;
  height: 40px !important;

  &::-moz-placeholder { // Firefox
    color: #b7b7b7;
    opacity: 1; }
  &:-ms-input-placeholder { // Internet Explorer 10+
    color: #b7b7b7; }
  &::-webkit-input-placeholder  { // Safari and Chrome
    color: #b7b7b7; }
}

.header {
  display: block;
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  height: auto;
  padding: 0;
  background: #fff;

  &.fixed {
    position: relative;
    top: auto;
    left: auto;
    height: auto;
    padding: 0;
    z-index: 800;

    &.avaliacoes {
      height: auto; }
  }

  &.compras {
    height: 86px; }

  &.clear {
    background: none;
    border-bottom: 1px solid transparent;
    @include box-shadow(none); }
}

.h3-header {
  margin-top: 28px;
  color: #595959;

  &.small-margin {
    margin-bottom: 7px; }

  &.big-margin {
    margin-top: 28px; }
}

.fg-resposta {
  position: relative;
  float: left;
  width: 100%;

  &.has-unidade {
		width: 62%;
		padding-right: 10px; }

  &.has-unidade2 {
		width: 62%;
		padding-right: 10px; }

  &.has-error > input {
    border: 1px solid red; }
}

.fg-unidade {
  position: relative;
  float: left;
  width: 38%;

  &.has-error > input {
    border: 1px solid red; }
}

.unidade-wrapper {
  position: relative;
  float: left;
  width: 30%;
  padding: 0 0 0 8px; }

// custom pagination
.pagination > li > a, .pagination > li > span {
	background-color: transparent;
	border: 0px solid #ddd !important;
	border-radius: 5px;
	margin-left: 20px;
	color: #414548;
}

.pagination > .break-item > a:hover {
	cursor: default;
	background-color: inherit;
}

.pagination > .active > a{
	border-radius: 5px;
	background-color: #0076D6;
}
.pagination > .disabled > a {
	background-color: inherit;
}
$border-color: #aeaeae;
$background-color: #f0f0f0;
$selected-color: #216ba5;
$highlighted-color: #3dcc4a;
$muted-color: #ccc;
$text-color: #000;

$font-size: 14px;
$border-radius: 0;
$item-size: 38px;
$day-margin: .166rem;
$triangle-size: 8px;
$datepicker__margin: .4rem;
$navigation-size: .45rem;

%triangle-arrow {
  margin-left: -$triangle-size;
  position: absolute;

  &,
  &::before {
    box-sizing: content-box;
    position: absolute;
    border: $triangle-size solid transparent;

    height: 0;
    width: 1px;
  }

  &::before {
    content: "";
    z-index: -1;
    border-width: $triangle-size;

    left: -$triangle-size;
    border-bottom-color: $border-color;
  }
}

%triangle-arrow-up {
  @extend %triangle-arrow;

  top: 0;
  margin-top: -$triangle-size;

  &,
  &::before {
    border-top: none;
    border-bottom-color: $background-color;
  }

  &::before {
    top: -1px;
    border-bottom-color: $border-color;
  }
}

%triangle-arrow-down {
  @extend %triangle-arrow;

  bottom: 0;
  margin-bottom: -$triangle-size;

  &,
  &::before {
    border-bottom: none;
    border-top-color: #fff;
  }

  &::before {
    bottom: -1px;
    border-top-color: $border-color;
  }
}

.react-datepicker-popper {
  z-index: 2000;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: $font-size;
  background-color: #fff;
  color: $text-color;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  display: inline-block;
  position: relative;
  min-width: 300px;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle {
  @extend %triangle-arrow-up;
}

.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle {
  @extend %triangle-arrow-down;
}

.react-datepicker__tether-element-attached-bottom.react-datepicker__tether-element {
  margin-top: -20px;
}

.react-datepicker__header {
  text-align: center;
  background-color: $background-color;
  border-bottom: 1px solid $border-color;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: $font-size * 1.18;
}

.react-datepicker__navigation {
  line-height: $item-size;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  border: $navigation-size solid transparent;
  z-index: 1;

  &--previous {
    left: 10px;
    border-right-color: $muted-color;

    &:hover {
      border-right-color: darken($muted-color, 10%);
    }
  }

  &--next {
    right: 10px;
    border-left-color: $muted-color;

    &:hover {
      border-left-color: darken($muted-color, 10%);
    }
  }

  &--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &-previous {
      top: 4px;
      border-top-color: $muted-color;

      &:hover {
        border-top-color: darken($muted-color, 10%);
      }
    }

    &-upcoming {
      top: -4px;
      border-bottom-color: $muted-color;

      &:hover {
        border-bottom-color: darken($muted-color, 10%);
      }
    }
  }
}

.react-datepicker__month-container {
  display: inline;
  float: left;
  width: 100%;
}

.react-datepicker__month {
  margin: $datepicker__margin;
  text-align: center;
}

.react-datepicker__week-number {
  color: $muted-color;
  display: inline-block;
  width: $item-size;
  line-height: $item-size;
  text-align: center;
  margin: $day-margin;
}

.react-datepicker__day-name,
.react-datepicker__day {
  color: $text-color;
  display: inline-block;
  width: $item-size;
  line-height: $item-size;
  text-align: center;
  margin: $day-margin;
}

.react-datepicker__day {
  cursor: pointer;

  &:hover {
    border-radius: $border-radius;
    background-color: $background-color;
  }

  &--today {
    font-weight: bold;
  }

  &--highlighted {
    border-radius: $border-radius;
    background-color: $highlighted-color;
    color: #fff;

    &:hover {
      background-color: darken($highlighted-color, 5%);
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius: $border-radius;
    background-color: $selected-color;
    color: #fff;

    &:hover {
      background-color: darken($selected-color, 5%);
    }
  }

  &--keyboard-selected {
    border-radius: $border-radius;
    background-color: lighten($selected-color, 10%);
    color: #fff;

    &:hover {
      background-color: darken($selected-color, 5%);
    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($selected-color, .5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $background-color;
      color: $text-color;
    }
  }

  &--disabled {
    cursor: default;
    color: $muted-color;

    &:hover {
      background-color: transparent;
    }
  }
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view {
  border: 1px solid transparent;
  border-radius: $border-radius;

  &:hover {
    cursor: pointer;

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: darken($muted-color, 10%);
    }
  }

  &--down-arrow {
    @extend %triangle-arrow-down;
    border-top-color: $muted-color;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: $navigation-size;
  }

}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background-color: $background-color;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  text-align: center;
  border-radius: $border-radius;
  border: 1px solid $border-color;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &:hover {
    background-color: $muted-color;

    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: darken($muted-color, 10%);
    }

    .react-datepicker__navigation--years-previous {
      border-top-color: darken($muted-color, 10%);
    }
  }

  &--selected {
    position: absolute;
    left: 15px;
  }
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;

  &::after {
    background-color: $selected-color;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "\00d7";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 50%;
  }
}

.react-datepicker__today-button {
  background: $background-color;
  border-top: 1px solid $border-color;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__tether-element {
  z-index: 2147483647;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 3rem;
    line-height: 3rem;
  }

  // Resize for small screens
  @media (max-width: 400px), (max-height: 550px)  {
    .react-datepicker__day-name,
    .react-datepicker__day {
      width: 3rem;
      line-height: 3rem;
    }
  }

  .react-datepicker__current-month {
    font-size: $font-size * 1.8;
  }

  .react-datepicker__navigation {
    border: 1.8 * $navigation-size solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: $muted-color;

    &:hover {
      border-right-color: darken($muted-color, 10%);
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: $muted-color;

    &:hover {
      border-left-color: darken($muted-color, 10%);
    }
  }
}

.form-control-date-wrapper {
	position: relative;
  max-width: 158px;

	> i {
		position: absolute;
		top: 13px;
		left: 13px;
		color: #aaa;	}
}

.form-control-date {
	display: block;
	width: 100%;
	height: 40px;
	padding: 9px 12px 10px 37px;
	font-size: 14px;
	line-height: 1.42857;
	color: #555555;
	background: none;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 0px;
	-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }

.form-control-date:focus {
	border-color: #66afe9;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }

.result-li {
  outline: none;

  &.selected {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5 !important; }
}

.em {
	color: #c3c6c9; }

.scrollbars {
	position: relative;
	width: 100%;
	overflow: auto; }



.header-tab-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  white-space: nowrap;
  padding-top: 0px;

  // &:after {
  //   background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, #fff 100%);
  //   background: linear-gradient(to right, rgba(255,255,255,0) 0%, #fff 100%);
  //   content: '';
  //   height: 100%;
  //   position: absolute;
  //   right: 15px;
  //   top: 0;
  //   width: 2rem; }
}

.header-tab-item {
  display: inline-block;
  position: relative;
  // float: left;
  width: auto;
  margin: 15px 20px 0 0;
  padding: 0 4px 8px;
  border: none;
  border-bottom: 3px solid transparent;
  background: none;
  outline: none;
  color: #b3aead;

  > span {
    color: #ffffff;
    background: #d1d3d7;
    @include border-radius(60px);
    margin-left: 2px;
    padding: 3px 5px 2px;
    font-size: 12px;
    font-weight: 700; }

  &:hover {
    color: #999;
    border-bottom: 3px solid #ccc; }

  &.active {
    color: #0076D6;
    border-bottom: 2px solid #0076D6;
    font-weight: 500;

    > span {
      background: #D9EEFF;
      color: #0076D6;
    }
  }
}

.header-buttons-wrapper {
  text-align: right;
  padding-top: 30px; }

.header-tab-item-division {
  display: none; }

.error-wrapper {
	position: relative;
	text-align: center;
	font-size: 16px;

	> i {
		font-size: 90px;
		color: #cbced3; }

	> h3 {
		margin-bottom: 20px; }

	> p {
		color: #6d6e6f; }
}

.page-wrapper {
	position: relative;
	width: 100%;
	padding-left: 0;
	padding-bottom: $closed_width_mobile * 1px; }

.left-menu-wrapper {
	position: fixed;
	top: auto;
	bottom: 0;
	left: 0;
	width: 100%;
	height: $closed_width_mobile * 1px;
	background: $menu_background;
	@include transition-one(width .13s cubic-bezier(0.12, 0.0, 0.48, 1.0));
	z-index: $zindex_leftbar; }

.left-menu-wrapper > .menu-top {
	display: none; }

.left-menu-wrapper > .menu {
	float: left;
	width: 100%;
	padding: 0;
	margin: 1px 0 1px;
	list-style: none;
	font-size: 12px;
	text-align: left;

	> li {
		display: inline-block;
		float: left;
		width: 27%;
		text-align: -webkit-match-parent;

		> a {
			position: relative;
			display: block;
			width: 100%;
			padding: 34px 0 14px 0;
			color: #cad4e1;
			text-align: center;

			> svg {
				position: absolute;
				top: 7px;
				left: 50%;
				margin: 0 0 0 -15px;
				color: #94a9c9; }

			&:hover {
				background: darken($menu_background, 4%);
				color: #fff;
				text-decoration: none;

				> svg {
					color: #fff; }
			}

			&.active {
				background: #01b0cd;
				color: #fff;
				text-decoration: none;

				> svg {
					color: #fff; }
			}
		}

		&.divider {
			display: none; }
	}
}

.svg-cotacoes {
	top: 6px !important;
	left: 50% !important; }

.svg-compras {
	left: 50% !important;
	margin: 0 0 0 -11px !important; }

.li-new-buttons-wrapper {
	position: relative;
	padding: 7px 0 0 0;
	text-align: center !important; }

.new-button {
	position: relative;
	display: inline-block;
	width: 42px;
	height: 42px;
	margin: 0 auto 16px;
	padding: 0;
	border-radius: 60px;
	border: none;
	outline: none;
	background: #0076d6;
	color: #fff;
	font-size: 21px;
	text-align: center;
	@include transition-one(background .16s);

	&:hover {
		background: lighten(#0076d6, 4%);
		color: #fff;
		text-decoration: none; }
}


/* ================================================================== *\



    #Small devices (tablets, 768px and up)



\* ================================================================== */ @media (min-width: 768px) {


.page-wrapper {
	padding-left: $closed_width * 1px;
	padding-bottom: 0; }

.left-menu-wrapper {
	position: fixed;
	top: 0;
	bottom: auto;
	left: 0;
	width: $closed_width * 1px;
	height: 100%;
	height: 100vh;
	background: $menu_background; }

.left-menu-wrapper > .menu-top {
	display: block;
	position: relative;
	width: 100%;
	height: $top_height * 1px;
	padding: 17px 0 0 12px;
	background: darken($menu_background, 4%);
	border-bottom: 1px solid darken($menu_background, 8%);

	&.interno {
		background: #4abb7d; }
}

.left-menu-wrapper > .menu {
	padding: 16px 0 0;
	margin: 1px 0 1px;
	list-style: none;
	font-size: 12px;
	text-align: left;

	> li {
		display: list-item;
		float: none;
		width: auto;
		text-align: -webkit-match-parent;

		> a {
			position: relative;
			display: block;
			padding: 45px 0 14px 0;
			color: #cad4e1;
			text-align: center;

			> svg {
				position: absolute;
				top: 15px;
				left: auto;
				margin: 0;
				color: #94a9c9; }

			&:hover {
				background: darken($menu_background, 4%);
				color: #fff;
				text-decoration: none;

				> svg {
					color: #fff; }
			}

			&.active {
				background: #01b0cd;
				color: #fff;
				text-decoration: none;

				> svg {
					color: #fff; }
			}
		}

		&.divider {
			display: block;
			height: 2px;
			overflow: hidden;
			background-color: darken($menu_background, 4%);
			border-bottom: 1px solid lighten($menu_background, 8%); }
	}
}

.svg-cotacoes {
	top: 12px !important;
	left: 18px !important; }

.svg-compras {
	left: 23px !important;
	margin: 0 !important; }

.li-new-buttons-wrapper {
	padding: 0; }



.header {
  &.fixed {
    position: fixed;
    top: $top_height * 1px;
    left: 0;
    height: $top_fixed_header_height * 1px;
    padding: 0 0 0 $closed_width * 1px;

    &.avaliacoes {
      height: 118px; }
  }

  &.compras {
    height: $top_fixed_header_height - 14 * 1px; }
}

.h3-header {
  margin-top: 36px;
  margin-bottom: 0;
  font-weight: 300;

  &.big-margin {
    margin-top: 38px; }
}

.header-tab-item {
  display: inline-block;
  position: relative;
  float: left;
  width: auto;
  margin: 15px 40px 0 0;
  padding: 0 4px 10px;
  border: none;
  border-bottom: 3px solid transparent;
  background: none;
  outline: none;
  color: #8C8C8C;

  > span {
    color: #8c8c8c;
    background: #F5F5F5;
    @include border-radius(60px);
    margin-left: 2px;
    padding: 3px 5px 2px;
    font-size: 12px;
    font-weight: 500; }

  &:hover {
    color: #999;
    border-bottom: 3px solid #ccc; }

  &.active {
    color: #0076D6;
    border-bottom: 3px solid #0076D6;
    font-weight: 500;

    > span {
      background: #D9EEFF;
      color: #0076D6;
    }
  }
}

.header-tab-item-division {
  display: inline-block;
  position: relative;
  float: left;
  width: 1px;
  height: 47px;
  margin: 0 40px 0 0;
  background: #e8eaec;
  background: -moz-linear-gradient(top, #ffffff 0%, #e8eaec 60%);
  background: -webkit-linear-gradient(top, #ffffff 0%,#e8eaec 60%);
  background: linear-gradient(to bottom, #ffffff 0%,#e8eaec 60%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e8eaec',GradientType=0 ); }

.fg-resposta {
  width: 100%;

  &.has-unidade {
		width: 70%; }
}


/* ================================================================== *\



    #Medium devices (desktops, 992px and up)



\* ================================================================== */ } @media (min-width: 992px) {





/* ================================================================== *\



    #Large devices (large desktops, 1200px and up)



\* ================================================================== */ } @media (min-width: 1200px) {

  .container {
    width: 1200px !important;
  }

/* ================================================================== */ } /* END devices */

.ant-popover.ant-popconfirm.ant-popover-placement-top {
  width: 220px !important;
  max-width: 220px !important;
  margin-right: 1.5rem !important;
}

.ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight {
    width: 280px !important;
    top: 150px !important;
    margin-top: -32px !important;
}

.ant-popover-buttons {
    margin-top: -6px !important;
    margin-right: 1.5rem !important;
    border-radius: 2px !important;
}

.ant-form .ant-form-item .ant-form-item-label, 
.ant-form .ant-form-item .ant-form-item-control {
    width: 100% !important;
}

.ant-btn-round {
  font-size: 14px !important;
  padding: 6px 25px !important;
}

.ant-message {
  z-index: 3000 !important;
}

.ant-btn-round.ant-btn-lg {
  height: 32px !important;
}

@media(max-width: 1100px) {
  .ant-table-wrapper {
    overflow-x: scroll !important;
  }
}