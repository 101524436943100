@import "../../../styles/global_variables.scss";

$green_color: #53c33f;
.justify-flex-start {
	justify-content: flex-start;
	display: flex;
}
.iten-button {
	display: block;
	position: relative;
	width: -webkit-fill-available;
	width: 100%;
	background-color: transparent;
	border: 0px;
	margin-bottom: 4px;
	@include box-shadow(0 1px 1px 0 rgba(0, 0, 0, 0.03));
	padding: 0px;
	> .compra-wrapper {
		@include box-shadow(0 1px 1px 0 rgba(0, 0, 0, 0.03)); }

	&:hover > .compra-wrapper {
		@include box-shadow(0 1px 20px 0px rgba(0, 0, 0, 0.2)); }
}
button:focus{
	outline: none;
}
.caracteristicas{
	border-left: 1px solid #a3a9b259;

	> label {
		display: flex;
		padding-left: 10px;
		font-size: 16px;
		color: #333333;
		font-weight: inherit;
	}
	p {
		padding-left: 10px;
		display: flex;
		margin: 0;
		font-size: 13px;
		color: #a3a9b2;
		font-weight: inherit;
		span {
			padding-left: 10px;
			font-size: 13px;
			color: #333333;
		}
	}
}
.itens-wrapper {
	display: flex;
	position: relative;
	float: left;
	width: 100%;
	margin: 3px auto 0px;
	padding: 17px 30px 14px 52px;
	background: #fff;
	border-top: none;
	border-right: none;
	border-bottom: none;
	border-left: 2px solid #e8ecef;
	text-align: left;
	@include border-radius(4px);
	@include transition-one(all .1s linear);

	&:after {
		content: '';
		display: table;
		clear: both; }

	&.border {
		border-top: 1px solid #e4e7eb;
		border-right: 1px solid #e4e7eb;
		border-bottom: 1px solid #e4e7eb; }

	> i {
		position: absolute;
		top: 19px;
		left: 18px;
		color: #dee0e3;
		font-size: 18px; }

	> .anexos-icon {
		position: absolute;
		top: 88px;
		left: auto;
		right: 27px;
		color: #646567;
		font-size: 21px; }

	> .titulo {
		position: relative;
		float: left;
		width: 60%;
		padding: 0;
		font-size: 16px;
		color: #333333;

		.associado {
			color: #333333; }

		.no_associado {
			color: red; }

		.detalhado {
			color: #a0a6af; }

    > div {
			font-size: 16px;
			color: #333333;

			> p {
				margin: 0;
				font-size: 13px;
				color: #a3a9b2;
				font-weight: inherit;

				> .data {
					margin-right: 20px;
					font-size: 12px;
					color: #7b7e84;
				}
			}
		}
	}

	> .iten-associado {
		position: relative;
		float: left;
		width: 40%;
		padding: 4px 0 0 0;
		font-size: 16px;
		// text-align: right;
		justify-items: flex-start;
		flex-direction: row;
		flex-wrap: wrap;
		display: flex;
		align-items: center;

		> div {
			flex: 1 0 100%;
		}

		> span {
			font-size: 14px;
			color: #a0a6af;
		}
	}

	> .divisao {
		position: relative;
		float: left;
		width: 100%;
		height: 1px;
		margin: 10px auto;
		background: #eaecf1; }

	> .cotacao,
	> .fornecedor,
	> .condicao,
	> .entrega {
		position: relative;
		float: left;
		width: 14%;
		padding: 0 21px 0 0;
		font-size: 13px;
		color: #ccc;

		> p {
			margin: 0;
			font-size: 12px;
			color: #333333; }
	}

	> .fornecedor {
		width: 40%; }

	> .condicao {
		width: 27%; }

	> .entrega {
		width: 19%; }

}

.em {
	color: #c3c6c9; }


/* ================================================================== *\



    #Small devices (tablets, 768px and up)



\* ================================================================== */ @media (min-width: 768px) {


	.compra-row-2 {
		> i {
			left: 22px; }

		> .preco-fechado {
			width: 21%;
			padding: 17px 10px 16px 51px; // 21px 10px 0 51px
			font-size: 21px; }

		> .fornecedor { /* ============================== fornecedor */
			width: 31%;
			padding: 13px 10px 13px 40px; }

		> .info-adicional { /* ======================== status */
			display: block;
			position: relative;
			float: left;
			width: 22%;
			padding: 13px 0 0 40px;
			text-align: left;
			color: #333333;

			&.first {
				padding-left: 80px; }

			> .info {
				display: block;
				color: #bdbdbd;
				font-size: 13px; }
		}

		> .anexos { /* =============================== anexos */
			right: 21px; }
	}

	.compra-row-2.s0 > i {
		left: 23px; }

	.compra-row-2.s1 > i {
		left: 22px; }


	/* ================================================================== *\



      #Medium devices (desktops, 992px and up)



  \* ================================================================== */ } @media (min-width: 992px) {


	.compra-row-2 {
		> .info-adicional { /* ======================== status */
			display: block;
			width: 24%;
			padding: 13px 0 0 40px; }
	}


	/* ================================================================== *\



          #Large devices (large desktops, 1200px and up)



  \* ================================================================== */ } @media (min-width: 1200px) {





	/* ================================================================== */ } /* END devices */
